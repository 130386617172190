import type { Issue } from '~/api/types/generated/Issue'
import type { MetaSocial } from '~/api/types/generated/components/MetaSocial'

type Seo = Partial<Issue['attributes']['seo']>
type Image = { url?: string, alternativeText?: string }

const defaultImage: Image = {
  url: '/img/thumbnail.jpg',
  alternativeText: 'Srovnávač dluhopisů Dluhopisomat',
}

export function useSeo(seo: Seo, image?: Image) {
  const metaSocial = useKeyBy(seo?.metaSocial, 'socialNetwork')

  if (!image) {
    image = defaultImage
  }

  const metaFb = metaSocial?.Facebook
  const metaTwitter = metaSocial?.Twitter
  const metaGoogle = metaSocial?.Google

  useHead({
    ...(seo?.metaTitle && { title: seo?.metaTitle }),
    meta: [
      ...(seo?.metaDescription ? [{ name: 'description', content: seo?.metaDescription }] : []),
      ...(seo?.keywords ? [{ name: 'keywords', content: seo.keywords }] : []),
      ...getGoogleMeta(metaGoogle, seo, image),
      ...getFacebookMeta(metaFb, seo, image),
      ...getTwitterMeta(metaTwitter, seo, image),
    ].filter(entry => entry.content !== undefined),
  })
}

// Schema.org markup for Google+
function getGoogleMeta(seo: MetaSocial | undefined, defaultSeo: Seo, image?: Image) {
  return [
    { itemprop: 'name', content: seo?.title || defaultSeo?.metaTitle },
    { itemprop: 'description', content: seo?.description || defaultSeo?.metaDescription },
    { itemprop: 'image', content: seo?.image?.data.attributes.url || image?.url },
    { property: 'image:alt', content: seo?.imageAlternativeText || image?.alternativeText },
  ]
}

// Open Graph / Facebook
function getFacebookMeta(seo: MetaSocial | undefined, defaultSeo: Seo, image?: Image) {
  return [
    { property: 'og:title', content: seo?.title || defaultSeo?.metaTitle },
    { property: 'og:description', content: seo?.description || defaultSeo?.metaDescription },
    { property: 'og:image', content: seo?.image?.data?.attributes.url || image?.url },
    { property: 'og:image:alt', content: seo?.imageAlternativeText || image?.alternativeText },
  ]
}

// Twitter
function getTwitterMeta(seo: MetaSocial | undefined, defaultSeo: Seo, image?: Image) {
  return [
    { property: 'twitter:card', content: 'summary' },
    { property: 'twitter:title', content: seo?.title || defaultSeo?.metaTitle },
    { property: 'twitter:description', content: seo?.description || defaultSeo?.metaDescription },
    { property: 'twitter:image', content: seo?.image?.data.attributes.url || image?.url },
    { property: 'twitter:image:alt', content: seo?.imageAlternativeText || image?.alternativeText },
  ]
}
